/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return */
import {useState, useEffect, useCallback, useRef} from 'react'

type OnWatchEventHandlerProps = {
  origin: any
  source: any
  data: any
}

const postMessage = (data: any, target: any, origin = '*') =>
  target?.postMessage(data, origin)
const useMessage = (watch?: any, eventHandler?: any) => {
  const [history, setHistory] = useState<any>([])
  const [originState, setOriginState] = useState<any>()
  const [sourceState, setSourceState] = useState<any>()

  const originRef = useRef()
  const sourceRef = useRef()

  originRef.current = originState
  sourceRef.current = sourceState

  const sendToSender = (data: any) =>
    postMessage(data, sourceRef.current, originRef.current)

  const sendToParent = (data: any) => {
    const opener = window.parent
    if (!opener) {
      return
    }
    postMessage(data, opener)
  }

  const onWatchEventHandler = useCallback(
    ({origin, source, data}: OnWatchEventHandlerProps) => {
      const {type, payload} = data
      if (type === watch) {
        setSourceState(source)
        setOriginState(origin)
        setHistory((old: any) => [...old, payload])
        eventHandler?.(sendToSender, payload)
      }
    },
    [watch, eventHandler, setSourceState, setOriginState]
  )

  useEffect(() => {
    window.addEventListener('message', onWatchEventHandler)
    return () => window.removeEventListener('message', onWatchEventHandler)
  }, [watch, sourceState, originState, onWatchEventHandler])

  return {history, sendToParent}
}

export default useMessage
