/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return */
import {useCallback, useEffect, useRef} from 'react'
import {PremiumPlayer} from 'playcraft/react'
import {playlogv2, selectHlsQualities} from 'playcraft/modules'
import './App.css'
import useQueryParameters from './utils/useQueryParameters'
import useMessage from './utils/useMessage'

const App = () => {
  const {logEventNames, mapLogEvents} = playlogv2
  const {
    hls,
    dash,
    thumbnailsUrl,
    drmToken,
    drmUrl,
    autoplay,
    // startTime,
    // lang,
  } = useQueryParameters()
  const {sendToParent} = useMessage()
  const drm = drmUrl
    ? {
        url: drmUrl,
        ...(drmToken && {
          headers: {
            Authorization: `Bearer ${drmToken}`,
          },
        }),
        widevine: {
          level: '',
        },
        fairplay: {
          certificateURL: `${drmUrl}/fairplay_cert`,
          ...(drmToken && {
            certificateHeaders: {
              Authorization: `Bearer ${drmToken}`,
            },
          }),
        },
        }
      : undefined

  const isMountedRef = useRef<boolean>(false)
  const logTarget = useRef<any>()
  const videoRef = useRef<any>()

  const initPlaylog = useCallback(() => {
    logTarget.current?.reset()
    logTarget.current = mapLogEvents({
      playerName: 'shaka',
      version: process.env.npm_package_version,
      video: videoRef.current,
    })
    logTarget.current.all((type: string, data: any) =>
      sendToParent({
        type: logEventNames[type as keyof typeof logEventNames],
        message: data,
      })
    )
  }, [logEventNames, mapLogEvents, sendToParent])

  useEffect(() => {
    if (isMountedRef.current) {
      return undefined
    }
    initPlaylog()
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [initPlaylog])

  return (
    <div className="App">
      <PremiumPlayer
        videoRef={videoRef}
        source={{dash, hls}}
        thumbnailsUrl={thumbnailsUrl || undefined}
        drm={drm}
        autoplay={typeof autoplay === 'string' ? Boolean(autoplay) : true}
        sendLog={(name: string, data: any, properties: any) =>
          logTarget.current?.emit(name, data, properties)
        }
        quality={{rewriteManifest: selectHlsQualities}}
      />
    </div>
  )
}

export default App
