import {useLocation} from 'react-router-dom'

type QueryParameters = {
  hls: string | null
  dash: string | null
  thumbnailsUrl: string | null
  drmToken: string | null
  drmUrl: string | null
  autoplay: string | null
  startTime: string | null
  lang: string | null
}

const useQueryParameters = (): QueryParameters => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
  const {search}: {search: string} = useLocation()
  // Note: currently ignore IE 11
  // eslint-disable-next-line compat/compat
  const query = new URLSearchParams(search)
  const hls = query.get('hls')
  const dash = query.get('dash')
  const thumbnailsUrl = query.get('thumbnails_url')
  const drmToken = query.get('drm_token')
  const drmUrl = query.get('drm_url')
  const autoplay = query.get('autoplay')
  const startTime = query.get('s')
  const lang = query.get('hl')

  return {
    hls,
    dash,
    thumbnailsUrl,
    drmToken,
    drmUrl,
    autoplay,
    startTime,
    lang,
  }
}

export default useQueryParameters
